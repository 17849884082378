/* Garantir que a página ocupe toda a altura da janela */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: hsl(277, 38%, 54%);
  color: #333;
}

/* Tornar a App flexível para organizar o layout */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que a altura mínima seja 100% da altura da tela */
}

/* Header permanece no topo */
.App-header {
  background-color: white;
  padding: 20px;
  color: hsl(277, 38%, 54%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.App-header img {
  height: 40px;
  margin-right: 10px; /* Espaço entre a imagem e o texto */
}

.App-header h1 {
  font-size: 1.8rem;
  margin: 0;
}

/* Botão no header */
.access-button {
  background-color: hsl(277, 38%, 54%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  margin-left: auto; /* Alinha o botão à direita */
}

.access-button:hover {
  background-color: hsl(277, 38%, 60%);
}

/* Main flexível e cresce para ocupar espaço disponível */
main {
  flex: 1; /* Flexível, ocupa o espaço disponível */
  padding: 40px 20px;
}

/* About e Services Section */
.section-about, .section-services {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Título das Seções */
.section-about h2 {
  margin-top: 0;
  text-align: center;
  color: hsl(277, 38%, 40%);
  font-size: 2rem;
}

.section-services h2 {
  margin-top: 0;
  color: hsl(277, 38%, 40%);
  font-size: 2rem;
}

.section-about h3, .section-services h3 {
  margin-top: 0;
  color: hsl(277, 38%, 40%);
  font-size: 2rem;
}

/* Estilo dos Service Boxes */
.section-services {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service-box {
  flex: 1;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.service-icon {
  max-width: 80px;
  margin-bottom: 15px;
}

.service-box h3 {
  color: hsl(277, 38%, 50%);
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-box p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.service-box button {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: hsl(277, 38%, 54%);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-box button:hover {
  background-color: hsl(277, 38%, 60%);
}

/* Footer permanece fixo na parte inferior */
.App-footer {
  background-color: white;
  padding: 10px 20px;
  color: hsl(277, 38%, 54%);
  text-align: center;
  margin-top: auto; /* Empurra o rodapé para a parte inferior */
}

.App-footer nav a {
  color: hsl(277, 38%, 54%);
  margin: 0 10px;
  text-decoration: none;
}

.App-footer nav a:hover {
  text-decoration: underline;
}

/* Overlay de Loading */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1000;
}

/* Media Query para telas menores */
@media (max-width: 768px) {
  .App-header {
    flex-direction: row; /* Mantém o layout na horizontal */
    justify-content: flex-start; /* Alinha o conteúdo à esquerda */
    text-align: left; /* Alinha o texto à esquerda */
  }

  .App-header img {
    margin-right: 10px; /* Espaço entre a imagem e o texto */
  }

  .section-services {
    flex-direction: column;
  }

  .service-box {
    margin-bottom: 20px;
  }

  .service-box:last-child {
    margin-bottom: 0;
  }

  main {
    padding: 20px 10px;
  }
}

@media (max-width: 480px) {
  .App-header h1 {
    font-size: 1.5rem;
  }

  .section-about h2, .section-services h2 {
    font-size: 1.5rem;
  }

  .section-about h3, .section-services h3 {
    font-size: small;
  }

  .service-box h3 {
    font-size: 1.2rem;
  }

  .service-box p {
    font-size: 0.9rem;
  }

  .service-box button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
